import axios from '@/plugins/axios'

/**
 * Download NFSe
 */
const downloadNFSe = async id => {
  try {
    const response = await axios.get(`admin/orders/${id}/nfse/download`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get order
 */
const getOrder = async (id) => {
  try {
    const response = await axios.get(`admin/orders/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get orders
 */
const getOrders = async ({
  // eslint-disable-next-line camelcase
  advertiser_id = null,
  // eslint-disable-next-line camelcase
  amount_end = 0,
  // eslint-disable-next-line camelcase
  amount_start = 0,
  // eslint-disable-next-line camelcase
  end_date = null,
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  start_date = null,
  // eslint-disable-next-line camelcase
  end_date_pay = null,
  // eslint-disable-next-line camelcase
  start_date_pay = null,
  status = null
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (advertiser_id) params.advertiser_id = advertiser_id
    // eslint-disable-next-line camelcase
    if (amount_start > 0) params.amount_start = parseInt(amount_start * 100)
    // eslint-disable-next-line camelcase
    if (amount_end > 0) params.amount_end = parseInt(amount_end * 100)
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    // eslint-disable-next-line camelcase
    if (end_date_pay) params.end_date_pay = end_date_pay
    // eslint-disable-next-line camelcase
    if (start_date_pay) params.start_date_pay = start_date_pay
    if (search) params.search = search
    if (status) params.status = status

    const response = await axios.get('admin/orders', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Issue new boleto
 */
const issueNewBoleto = async (id, paymentExpirationDate) => {
  try {
    const response = await axios.post(`admin/orders/${id}/issue-new-boleto`, {
      payment_expiration_date: paymentExpirationDate
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Issue NFSe
 */
const issueNFSe = async id => {
  try {
    const response = await axios.post(`admin/orders/${id}/nfse/issue`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  downloadNFSe,
  getOrder,
  getOrders,
  issueNewBoleto,
  issueNFSe
}
