<template>
  <b-modal
    :id="`order-detail-modal-${orderId}${orderClaimId||''}`"
    :modal-class="'default-modal modal-lg'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Detalhes de pagamento</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <template v-if="loader">
        <div class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
      </template>
      <template v-else-if="order">
        <h3 class="font-size-medium">Dados gerais</h3>
        <p>
          <strong>Anunciante:</strong> {{ order.advertiser.name }}<br/>
          <strong>Valor:</strong> {{ order.amount / 100 | formatMoney }}<br/>
          <strong>Data de criação:</strong> {{ order.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
          <strong>URL de pagamento:</strong> <a :href="order.payment_url" target="_blank">{{ order.payment_url }}</a><br/>
          <strong>Status do pagamento:</strong> {{ order.payment_status | formatPaymentStatus }}<br/>
          <strong>Status da NFSe:</strong> {{ order.nfse_status | formatNFSeStatus }}<br/>
          <strong>Ativo:</strong> {{ order.is_active ? 'Sim' : 'Não' }}
        </p>
        <template v-if="order.insertion_order">
          <p>
            <router-link
              :to="{ name: 'insertion.orders.show', params: { id: order.insertion_order.id }}"
              class="fw-bold fs-6"
            >
              Clique aqui para ver detalhes do PI
            </router-link>
          </p>
        </template>
        <template v-else>
          <p>
            <strong>Criado através de PI:</strong> Não
          </p>
        </template>
        <template v-if="invoice">
          <h3 class="font-size-medium">Dados de pagamento</h3>
          <p>
            <strong>Forma de pagamento:</strong> {{ invoice.payment_method | formatPaymentMethod }}<br/>
          </p>
          <template v-if="invoice.payment_method === 'BOLETO'">
            <template v-if="this.order.invoices.length == 1">
              <p class="mb-3">
                <strong>Código de barras:</strong> {{ invoice.boleto_barcode }}<br/>
                <strong>Data de vencimento:</strong> {{ invoice.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
                <strong>Link para impressão do boleto:</strong> <a :href="invoice.boleto_url" target="_blank">{{ invoice.boleto_url }}</a>
              </p>
            </template>
            <div class="mb-4 border px-5 py-3 rounded" v-if="order.insertion_order">
              <h5 class="font-size-regular mb-1">Emitir novo boleto</h5>
              <div class="d-flex align-items-center">
                <label class="required form-label col-2 me-3">Data de validade</label>
                <DateTimeInput
                  v-model.trim="payment_expiration_date"
                  format="dd/MM/yyyy"
                  type="date"
                  theme="basic"
                  class="col-2 me-3"
                  :min="true"
                />
                <Button
                    class="btn btn-sm btn-primary"
                    @click="issueNewBoleto()"
                    :loading="loaderButton"
                  >
                    Reemitir boleto
                </Button>
              </div>
            </div>
            <template v-if="this.order.invoices.length > 1">
              <div>
                <table class="table table-rounded table-striped border gy-2 gs-7 mb-5">
                  <thead>
                    <tr class="fw-bold">
                      <th>Cod barras</th>
                      <th>Link</th>
                      <th>Status</th>
                      <th>Vencimento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, index) in this.order.invoices" :key="index">
                      <td>{{ i.boleto_barcode }}</td>
                      <td><a :href="invoice.boleto_url" target="_blank">{{invoice.boleto_url}}</a></td>
                      <td>{{ i.payment_status | formatPaymentStatus }}</td>
                      <td>{{ i.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </template>
          <template v-else-if="invoice.payment_method === 'PIX'">
            <p><strong>Data de vencimento:</strong> {{ invoice.pix_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</p>
            <PIXQRCodeCard
              :code="invoice.pix_qr_code"
            />
          </template>
          <template v-else-if="invoice.payment_method === 'CREDIT_CARD'">
            <p>
              <strong>Bandeira do cartão:</strong> {{ invoice.card_brand | formatCreditCardBrand }}<br/>
              <strong>Cartão de final:</strong> {{ invoice.card_last_digits }}
            </p>
          </template>
          <template v-if="lastInvoice.payment_logs.length > 0">
            <h5 class="font-size-regular mb-3">Histórico de pagamento</h5>
            <table class="table table-rounded table-striped border gy-2 gs-7 mb-5">
              <thead>
                <tr class="fw-bold">
                  <th>Status</th>
                  <th>Data de cobrança</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(pl, index) in lastInvoice.payment_logs" :key="index">
                  <td>{{ pl.status | formatPaymentStatus }}</td>
                  <td>{{ pl.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}</td>
                </tr>
              </tbody>
            </table>
          </template>
        </template>
        <template v-if="order.order_claims.length > 0">
          <h3 class="font-size-medium">Reivindicações</h3>
          <ul>
            <li v-for="(order_claim, index) in order.order_claims" :key="index">{{ order_claim.name  }} - <strong>{{ order_claim.status | formatClaimStatus }}</strong> - {{ order_claim.type == 'RENOVATION' ? 'Renovação' : 'Primeira compra' }}</li>
          </ul>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'OrderDetailModal',
  props: ['orderId', 'orderClaimId'],
  components: {
    AlertWraper,
    Button,
    DateTimeInput,
    Loader
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      order: null,
      payment_expiration_date: '',
      loaderButton: false
    }
  },
  computed: {
    invoice () {
      return this.order && this.order.invoices.length > 0 ? this.order.invoices[0] : null
    },
    lastInvoice () {
      const invoiceCount = this.order.invoices.length
      return this.order.invoices[invoiceCount - 1]
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.order = null
      this.content = null
    },
    /**
     * On modal shown
     */
    shown () {
      this.getOrder()
    },
    /**
     * Get order
     */
    async getOrder () {
      this.loader = true
      try {
        const order = await OrdersService.getOrder(this.orderId)
        this.order = order
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Issue new boleto
     */
    async issueNewBoleto () {
      this.loaderButton = true
      try {
        await OrdersService.issueNewBoleto(this.orderId, this.payment_expiration_date)
        const notification = {
          notification: {
            type: 'success',
            content: 'Emitido com sucesso'
          }
        }
        this.$bvModal.hide(`order-detail-modal-${this.orderId}`)
        NotificationService.createGlobalSuccessNotification(notification)
        location.reload()
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loaderButton = false
    }
  }
}
</script>
